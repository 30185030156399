<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Zone Groups')}}</h2>
      <span class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="openZoneModal('add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large" 
        >
        </vs-button>
      </span>
    </div>

    <shipblu-table
      multiple
      v-model="selected"
      :sst="true"
      :data="zoneGroups"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Warehouse')}}</shipblu-th>
        <shipblu-th>{{$t('Zones')}}</shipblu-th>
        <shipblu-th>{{$t('Visit Days')}}</shipblu-th>
        <shipblu-th class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].name" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50">
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].name }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].warehouse">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Warehouse')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].served_by ? data[indextr].served_by.name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].zones">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zones')}}</p>
            <span class="sm:text-base text-sm" :key="zone.index" v-for="zone in data[indextr].zones">
              {{ $t(zone.name) }}, 
            </span>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].days">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Visit Days')}}</p>
            <span class="sm:text-base text-sm" :key="day.index" v-for="day in data[indextr].visit_days">
              {{ day.day }}, 
            </span>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 160px">
                  <vs-dropdown-item
                    @click="openZoneModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteZone(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-zone-group :zoneGroupModal="zoneGroupModal" :warehouses="warehouses" @zoneGroupModal="zoneGroupModal = $event" :zoneGroup="zoneGroup" :type="type" @loadZones="loadZoneGroup"></add-zone-group>
   </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import i18nData from '../../i18n/i18nData.js'
import AddZoneGroup from './components/AddZoneGroup.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      zoneGroups: [],
      deleteData: {},
      selected: [],
      zoneGroupModal: false,
      zoneGroup: {},
      type: '',
      zones: [],
      warehouses: [],
      totalRows: 0,
      offset: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: 'admin-management-zone-groups',
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadZoneGroup()
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
    },
    loadZoneGroup () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/zone-groups/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true,
        (response) => {
          this.zoneGroups = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    openZoneModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.zoneGroup = {
          name: '',
          served_by: '',
          zones: [],
          warehouses: []
        } 
      } else {
        this.zoneGroup = data
      }
      this.zoneGroupModal = true
    },
    deleteZone (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/zone-groups/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Zone group'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadZoneGroup()
        }
      )
    }
  },
  components: {
    AddZoneGroup,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.loadZoneGroup()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>
